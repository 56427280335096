import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Check, Star } from 'react-feather';
import './Pricing.css';
import SubscriptionHandler from './SubscriptionHandler';
import { useUser } from '../../UserContext';
import { useSnackbar } from 'notistack';

const PricingCard = ({ id, title, price, originalPrice, discountPercentage, description, features, isFirst, isLast, isPremium, isCustom }) => {
  const { user, updateUserCredits, fetchCredits } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubscriptionSuccess = (subscription) => {
    updateUserCredits({
      basic: subscription.plan.basic_credits || 0,
      advanced: subscription.plan.advanced_credits || 0
    });
    enqueueSnackbar(`Successfully subscribed to ${title} plan!`, { variant: 'success' });
  };

  const handleSubscriptionError = (error) => {
    enqueueSnackbar(`Subscription failed: ${error}`, { variant: 'error' });
  };

  const handleContactUs = () => {
    const subject = encodeURIComponent("Custom Model Inquiry");
    const body = encodeURIComponent("Hello,\n\nI'm interested in a custom model for AIThumbnail. Could you please provide more information?\n\nThank you!");
    window.location.href = `mailto:support@hacktogether.dev?subject=${subject}&body=${body}`;
  };

  return (
    <Card
      className={`pricing-card glassmorphic ${isFirst ? 'first-card' : ''} ${isLast ? 'last-card' : ''} ${isPremium ? 'premium-card' : ''}`}
    >
      {discountPercentage && !isFirst && <div className="discount-banner">{discountPercentage}% OFF</div>}
      <Card.Body>
        <h3>{title}</h3>
        {!isCustom ? (
          <>
            <h2>
              {originalPrice && <span className="original-price">${originalPrice}</span>} ₹ {price}
            </h2>
            <p>{description}</p>
            {user ? (
              <SubscriptionHandler
                planId={id}
                amount={price * 100}
                onSuccess={handleSubscriptionSuccess}
                onError={handleSubscriptionError}
                className="try-out-btn"
                fetchCredits={fetchCredits}
              >
                Subscribe Now
              </SubscriptionHandler>
            ) : (
              <button className="try-out-btn" onClick={() => enqueueSnackbar('Please log in to subscribe', { variant: 'info' })}>
                Subscribe Now
              </button>
            )}
          </>
        ) : (
          <>
            <p>{description}</p>
            <button className="try-out-btn" onClick={handleContactUs}>Contact us</button>
          </>
        )}
        <div className="features">
          <div className="features-title">Features</div>
          <ul>
            {features.map((feature, index) => (
              <li key={index} className={feature.highlight ? 'highlight' : ''}>
                <div className="icon-circle">
                  {feature.highlight ? <Star size={14} /> : <Check size={14} />}
                </div>
                <span className="feature-text">
                  {feature.text}
                  {feature.replacement && <span className="replacement"> {feature.replacement}</span>}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

const Pricing = ({ onContactUs }) => {
  const pricingPlans = [
    {
      id: 1,
      title: "Basic",
      price: "299",
      description: "10 credits",
      features: [
        { text: "10 Basic Model credits" },
        { text: "Limited Downloads" }
      ],
    },
    {
      id: 2,
      title: "Standard",
      price: "999",
      discountPercentage: 33,
      description: "50 credits",
      features: [
        { text: "25 Basic Model credits" },
        { text: "25 Advanced Model credits" },
        { text: "2x downloads" },
      ],
    },
    {
      id: 3,
      title: "Premium",
      price: "2499",
      discountPercentage: 50,
      description: "150 credits",
      features: [
        { text: "50 Basic Model credits" },
        { text: "100 Advanced Model credits" },
        { text: "Unlimited downloads" },
        { text: "Early access to new features" },
        { text: "Most value for money", highlight: true },
      ],
    },
    {
      title: "Custom model",
      description: "Tailored to your needs",
      features: [
        { text: "Your Style" },
        { text: "Your Face" },
        { text: "Your Brand" },
      ],
      isCustom: true,
    },
  ];

  return (
    <section id="pricing" className="pricing-section">
    <div className="pricing-container">
      <h2 className="text-center mb-5">Pricing Plans</h2>
      <Row className="pricing-cards-row">
        {pricingPlans.map((plan, index) => (
          <Col key={index} xs={12} sm={6} lg={4} xl={3} className="pricing-card-col">
            <PricingCard 
              {...plan} 
              isFirst={index === 0} 
              isLast={index === pricingPlans.length - 1} 
              isPremium={index === pricingPlans.length - 2}
            />
          </Col>
        ))}
      </Row>
    </div>
  </section>
  );
};

export default Pricing;