import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserProvider } from './UserContext';
import AppContent from './AppContent';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <UserProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            autoHideDuration={5000}
            style={{ zIndex: 999999 }} 
          >
            <div className="App">
              <AppContent />
            </div>
          </SnackbarProvider>
        </UserProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;