import React from 'react';
import NavBar from '../NavBar/NavBar';

const DefaultLayout = ({ children }) => {
    return (
        <>
            {React.cloneElement(children, { renderNavBar: (activeSection) => <NavBar activeSection={activeSection} /> })}
        </>
    );
};

export default DefaultLayout;