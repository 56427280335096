import React, { useState } from 'react';
import { Twitter, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Banner.css';

const Banner = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/contact/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
        credentials: 'include',
      });

      const responseText = await response.text();


      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.detail || 'Failed to send message';
        } catch (e) {
          errorMessage = 'Unexpected server response';
        }
        throw new Error(errorMessage);
      }

      const data = JSON.parse(responseText);

      toast.success('Message sent successfully!');
      
      // Clear the form
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
  
      toast.error(`Failed to send message: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-banner">
      <div className="banner-content">
        <div className="contact-info">
          <h2>Let's Connect</h2>
          <h1>Have a question or idea?<br />We're here to help!<br />Reach out anytime.</h1>
          <div className="contact-details">
            <div className="contact-item">
              <Twitter size={20} />
              <a href="https://twitter.com/HacktogetherDev" target="_blank" rel="noopener noreferrer">@HacktogetherDev</a>
            </div>
            <div className="contact-item">
              <Mail size={20} />
              <span>support@hacktogether.dev</span>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <form onSubmit={handleSendMessage}>
            <input
              type="email"
              placeholder="E-mail address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <textarea
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <div className="form-footer">
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Send Message →'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="banner-footer">
        <div className="logo">AIThumbnail</div>
        <div className="footer-links">
          <Link to="/terms" className="footer-link">Terms and Conditions</Link>
          <Link to="/privacy" className="footer-link">Privacy Policy</Link>
          <Link to="/refund" className="footer-link">Refund Policy</Link>
        </div>
        <div className="copyright">© 2024 hacktogether.dev</div>
      </div>
    </div>
  );
};

export default Banner;
