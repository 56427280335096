import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import api from "./api";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [credits, setCredits] = useState({ basic: 0, advanced: 0 });
    const [freeCredits, setFreeCredits] = useState(0);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    function logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userData');
        setUser(null);
        setCredits({ basic: 0, advanced: 0 });
        setFreeCredits(0);
        setLoading(false);
        navigate('/');
        enqueueSnackbar('Logged out successfully', { variant: 'success' });
    }

    function checkAuth() {
        setLoading(true);
        const token = localStorage.getItem('accessToken');
        const storedUserData = localStorage.getItem('userData');
        if (token && storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            setUser(parsedUserData);
            api.get('/api/users/me/')
                .then(response => {
                    const updatedUserData = {
                        ...parsedUserData,
                        ...response.data,
                        fullName: response.data.name || `${response.data.first_name} ${response.data.last_name}`.trim(),
                        photoURL: response.data.picture || response.data.photo_url,
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                    };
                    setUser(updatedUserData);
                    localStorage.setItem('userData', JSON.stringify(updatedUserData));
                    fetchCredits();
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        logout();
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setUser(null);
            fetchFreeCredits();
            setLoading(false);
        }
    }

    function fetchCredits() {
        if (!user) return;
        return api.get('/api/credits/count/')
            .then(response => {
                setCredits({
                    basic: response.data.basic_credits,
                    advanced: response.data.advanced_credits
                });
            })
            .catch(error => {
                enqueueSnackbar(`Error fetching credits: ${error.response?.data || error.message}`, { variant: 'error' });
            });
    }

    function fetchFreeCredits() {
        if (user !== null) return;
        api.get('/api/credits/ip_credits/')
            .then(response => {
                setFreeCredits(response.data.amount);
            })
            .catch(error => {
                enqueueSnackbar(`Error fetching free credits: ${error.response?.data || error.message}`, { variant: 'error' });
            });
    }

    const updateUserCredits = (newCredits) => {
        setCredits(prevCredits => ({
            basic: prevCredits.basic + (newCredits.basic || 0),
            advanced: prevCredits.advanced + (newCredits.advanced || 0)
        }));
    };

    const handleGoogleLogin = async (codeResponse) => {
        
        try {
          
            const requestData = { code: codeResponse.code };

            const backendResponse = await api.post('/api/auth/google/', requestData);
          
    
            const { data } = backendResponse;
            
    
            localStorage.setItem('accessToken', data.access);
           
    
            localStorage.setItem('refreshToken', data.refresh);
            
    
            const userData = {
                ...data.user,
                fullName: data.user.name || `${data.user.first_name} ${data.user.last_name}`.trim(),
                photoURL: data.user.picture || data.user.photo_url,
                firstName: data.user.first_name,
                lastName: data.user.last_name,
            };
          
    
            setUser(userData);
            
    
            localStorage.setItem('userData', JSON.stringify(userData));
           
            
            
            await fetchCredits();
            
    
            setLoading(false);
            
    
            enqueueSnackbar('Logged in successfully with Google!', { variant: 'success' });
        
    
            navigate('/generate');
   
        } catch (error) {
            
            enqueueSnackbar(`Google login failed: ${error.response?.data?.error || error.message}`, { variant: 'error' });
            
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: handleGoogleLogin,
        flow: 'auth-code',
        scope: 'openid profile email',
    });

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        if (user) {
            fetchCredits();
        } else {
            fetchFreeCredits();
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ 
            user, 
            loading, 
            credits, 
            freeCredits,
            setFreeCredits,  
            googleLogin,
            logout, 
            checkAuth, 
            fetchCredits,
            fetchFreeCredits,
            updateUserCredits 
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);

export default UserContext;