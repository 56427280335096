import React, { useState, useEffect, useRef } from "react";
import { Element, scroller } from 'react-scroll';
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from '../../UserContext';
import { FaGoogle } from 'react-icons/fa';
import { ChevronDown } from 'react-feather';
import "./NavBar.css";

export const NavBar = ({ activeSection, pricingSectionRef  }) => {
    const [scrolled, setScrolled] = useState(false);
    const { user, loading, logout, googleLogin, credits } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const handleNavClick = (path) => {
        const sectionId = path === '/' ? 'home' : path.slice(1);
        navigate(path);
        
        // Scroll to the section
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navItems = [
        { path: '/', label: 'Home' },
        { path: '/features', label: 'Features' },
        { path: '/pricing', label: 'Pricing' },
        { path: '/constraints', label: 'Constraints' }
    ];

  


    const handleBuyCredits = () => {
        navigate('/pricing');
        setShowDropdown(false);
    };
    return (
        <Navbar expand="md" className={scrolled ? "navbar scrolled" : "navbar"}>
            <div className="navbar-container">
                <Link to="/" onClick={() => handleNavClick('/')} className="navbar-brand">AIThumbnail</Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        {navItems.map(({ path, label }) => (
                            <Nav.Link
                                key={path}
                                onClick={() => handleNavClick(path)}
                                className={`nav-link ${activeSection === (path === '/' ? 'home' : path.slice(1)) ? 'active' : ''}`}
                            >
                                {label}
                            </Nav.Link>
                        ))}
                    </Nav>
                    <div className="auth-buttons">
                        {loading ? (
                            <div className="loading">Loading...</div>
                        ) : user ? (
                            <div className="user-profile-dropdown" onClick={() => setShowDropdown(!showDropdown)}>
                                {user.photoURL && (
                                    <img
                                        src={user.photoURL}
                                        alt="User"
                                        className="user-avatar"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/path/to/default/avatar.webp';
                                        }}
                                    />
                                )}
                                <span className="user-name">
                                    {user.fullName || user.name || `${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email}
                                </span>
                                <ChevronDown size={20} color="white" />
                                {showDropdown && (
                                    <div className="dropdown-menu">
                                        <Link to="/generate" className="dropdown-item">Generate</Link>
                                        <div className="dropdown-item">
                                            Basic Credits: {credits.basic}
                                        </div>
                                        <div className="dropdown-item">
                                            Advanced Credits: {credits.advanced}
                                        </div>
                                        <div className="dropdown-item">
                                            <button onClick={handleBuyCredits} className="buy-credits-btn">Buy Credits</button>
                                        </div>
                                        <div className="dropdown-item" onClick={logout}>Logout</div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <button className="auth-btn google-btn" onClick={() => googleLogin()}>
                                <FaGoogle /> Continue with Google
                            </button>
                        )}
                    </div>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default NavBar;