import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col, Spinner, ProgressBar } from "react-bootstrap";
import { Download, ChevronLeft, ChevronRight, ArrowRight } from 'react-feather';
import { Element, scroller } from 'react-scroll';
import { FlipWords } from "../FlipWords";
import Features from "../Features/Features";
import Pricing from "../Pricing/Pricing";
import Banner from "../Banner/Banner";
import Constraints from "../Constraints/Constraints";
import "./Home.css";
import { useLocation } from 'react-router-dom';
import { useUser } from '../../UserContext';
import { useSnackbar } from 'notistack';
import api from '../../api';
import NavBar from "../NavBar/NavBar";
import skate from "../../assets/skateboard_1.webp";
import skin from "../../assets/skin_1.webp";
import workout from "../../assets/workout_2.webp";
import apache2 from "../../assets/apache_2.webp";
import steak from "../../assets/steak_2.webp";

const Home = () => {
    const location = useLocation();
    const [placeholder, setPlaceholder] = useState("");
    const staticText = "Give your YouTube video idea: ";
    const examples = ["Surviving one's 20s.", "How to do public speaking?", "Caligraphy 101"];
    const [exampleIndex, setExampleIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);
    const [showCurvedCarousel, setShowCurvedCarousel] = useState(false);
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
    const [activeCurvedCarouselIndex, setActiveCurvedCarouselIndex] = useState(1);
    const [isHovering, setIsHovering] = useState(false);
    const [userPrompt, setUserPrompt] = useState("");
    const [generatedImages, setGeneratedImages] = useState([]);
    const [selectedModel, setSelectedModel] = useState("basic");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const totalCredits = 3;
    const [generationProgress, setGenerationProgress] = useState(0);
    const { user, credits, freeCredits, fetchCredits, fetchFreeCredits, setFreeCredits } = useUser();
    const [downloadedImages, setDownloadedImages] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [activeSection, setActiveSection] = useState('home');
    const sectionRefs = useRef({});
    const autoResizeTextArea = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + 'px';
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {

                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        Object.values(sectionRefs.current).forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            Object.values(sectionRefs.current).forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    useEffect(() => {
        if (location.pathname === '/pricing') {
            const pricingSection = document.getElementById('pricing');
            if (pricingSection) {
                pricingSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            const sectionIds = ['home', 'features', 'pricing', 'constraints'];
            const scrollPosition = window.scrollY;

            sectionIds.forEach((sectionId) => {
                const section = document.getElementById(sectionId);
                if (section) {
                    const sectionTop = section.offsetTop;
                    const sectionHeight = section.clientHeight;

                    if (
                        scrollPosition >= sectionTop - 150 && 
                        scrollPosition < sectionTop + sectionHeight - 150 
                    ) {
                        setActiveSection(sectionId);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        const path = location.pathname.slice(1);
        setActiveSection(path || 'home');
    }, [location]);
    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            scroller.scrollTo(location.state.scrollTo, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }, [location]);


    const carouselImages = [
        { src: skate, alt: "skating tutorial", text: "Skating tutorial" },
        { src: skin, alt: "Korean skincare products review", text: "Korean skincare products review" },
        { src: workout, alt: "10 min workout routine", text: "10 min workout routine" },
        { src: apache2, alt: "How to use apache workflow", text: "How to use apache workflow" },
        { src: steak, alt: "Cooking a delicious steak", text: "Cooking a delicious steak" },
    ];

    const flipWords = ["Stunning", "Eye-catching", "Attractive", "Captivating", "Impressive"];

    const isGeneratePage = location.pathname === '/generate';

    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            scroller.scrollTo(location.state.scrollTo, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownOpen(prevState => !prevState);
    };

    const handleModelSelect = (model) => {
        setSelectedModel(model);
        setDropdownOpen(false);
    };

    const handleGenerateClick = async () => {
        setIsGenerating(true);
        setGenerationProgress(0);

        const simulateProgress = () => {
            setGenerationProgress(prevProgress => {
                if (prevProgress >= 90) {
                    clearInterval(simulationInterval);
                    return prevProgress;
                }
                return prevProgress + 10;
            });
        };
        const simulationInterval = setInterval(simulateProgress, 500);

        try {
            const response = await api.post('/api/images/generate/', {
                prompt: userPrompt,
                model_type: selectedModel
            });

            clearInterval(simulationInterval);
            setGenerationProgress(100);

            if (response.data.images && response.data.images.length > 0) {
                setGeneratedImages(response.data.images);
                setShowCurvedCarousel(true);
                setActiveCurvedCarouselIndex(0);
                document.querySelector('.left-half')?.classList.add('move-up');

                if (user) {
                    await fetchCredits();
                    const planType = user.planType || 'basic';
                    const downloadLimit = planType === 'standard' ? 2 : 1;
                    enqueueSnackbar(`Images generated successfully! You can download ${downloadLimit} image${downloadLimit > 1 ? 's' : ''} with your ${planType} plan.`, { variant: 'success' });
                } else {
                    try {
                        const freeCreditsResponse = await api.get('/api/credits/ip_credits/');
                        if (typeof setFreeCredits === 'function') {
                            setFreeCredits(freeCreditsResponse.data.amount);
                        }
                    } catch (error) {
                        enqueueSnackbar(`Error fetching free credits: ${error.response?.data || error.message}`, { variant: 'error' });
                    }
                    enqueueSnackbar('Images generated successfully! Subscribe to download images.', { variant: 'success' });
                }

                setDownloadedImages([]);
            } else {
                throw new Error('No images received from the server');
            }
        } catch (error) {
            enqueueSnackbar(`Failed to generate images: ${error.response?.data?.detail || error.message}`, { variant: 'error' });
        } finally {
            clearInterval(simulationInterval);
            setIsGenerating(false);
            setTimeout(() => setGenerationProgress(0), 1000);
        }
    };

    const handleDownload = async (image) => {
        if (!user) {
            enqueueSnackbar("Sorry, you need a subscription to download images.", { variant: 'error' });
            return;
        }

        const planType = user.planType || 'basic';
        const downloadLimit = planType === 'standard' ? 2 : 1;

        if (downloadedImages.length >= downloadLimit) {
            enqueueSnackbar(`You've reached your download limit for your ${planType} plan.`, { variant: 'error' });
            return;
        }

        try {
            enqueueSnackbar('Download started...', { variant: 'info' });

            const response = await fetch(image.url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `generated-thumbnail-${image.id}.webp`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setDownloadedImages([...downloadedImages, image.id]);

            enqueueSnackbar('Image downloaded successfully!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(`Failed to download image: ${error.message}`, { variant: 'error' });
        }
    };

    useEffect(() => {
        if (charIndex < staticText.length) {
            const timeoutId = setTimeout(() => {
                setPlaceholder(staticText.slice(0, charIndex + 1));
                setCharIndex(charIndex + 1);
            }, 100);
            return () => clearTimeout(timeoutId);
        } else {
            const currentExample = examples[exampleIndex];
            if (charIndex < staticText.length + currentExample.length) {
                const timeoutId = setTimeout(() => {
                    setPlaceholder(staticText + currentExample.slice(0, charIndex - staticText.length + 1));
                    setCharIndex(charIndex + 1);
                }, 100);
                return () => clearTimeout(timeoutId);
            } else {
                const timeoutId = setTimeout(() => {
                    setCharIndex(staticText.length);
                    setExampleIndex((exampleIndex + 1) % examples.length);
                }, 2000);
                return () => clearTimeout(timeoutId);
            }
        }
    }, [charIndex, exampleIndex, examples, staticText]);

    const rotateCurvedCarousel = (direction) => {
        setActiveCurvedCarouselIndex((prevIndex) => {
            const newIndex = (prevIndex + direction + 3) % 3;
            return newIndex;
        });
    };

    const nextSlide = useCallback(() => {
        setActiveCarouselIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, [carouselImages.length]);

    const prevSlide = () => {
        setActiveCarouselIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [nextSlide]);

    const bannerRef = useRef(null);
    const pricingSectionRef = useRef(null);

    const scrollToBanner = () => {
        bannerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        setDownloadedImages([]);
    }, [generatedImages]);
    const updateActiveSection = (section) => {
        setActiveSection(section);
    };

    const renderHomeContent = () => (
        <Row className="justify-content-center align-items-center min-vh-95 main-content">
            <Col xs={12} md={isGeneratePage ? 8 : 6} className={`${isGeneratePage ? 'text-center' : 'left-half'}`}>
                <h1 className={isGeneratePage ? "generate-heading" : ""}>
                    {isGeneratePage ? (
                        <>Hello, {user?.firstName && user?.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : user?.displayName || 'User'}</>
                    ) : (
                        <>
                            <span className="first-line">
                                Create{" "}
                                <FlipWords
                                    words={flipWords}
                                    className="inline-block-flip"
                                />
                                {" "}YouTube
                            </span>
                            <span className="second-line">Thumbnails Instantly!</span>
                        </>
                    )}
                </h1>
                <p className={isGeneratePage ? "generate-subheading" : ""}>
                    {isGeneratePage ? "Let's create fun YouTube thumbnails!" : "Generate eye-catching thumbnails with just a few clicks!"}
                </p>
                <div className="thumbnail-generator">
                    <div className="orange-circle top-right"></div>
                    <div className="orange-circle bottom-left"></div>
                    <div className="input-wrapper">
                        <textarea
                            placeholder={placeholder}
                            className="thumbnail-generator-input"
                            value={userPrompt}
                            onChange={(e) => {
                                setUserPrompt(e.target.value);
                                autoResizeTextArea(e);
                            }}
                            onFocus={() => setPlaceholder("")}
                            onBlur={() => setPlaceholder(staticText)}
                            rows="1"
                        />
                        <button
                            className="generate-btn"
                            onClick={handleGenerateClick}
                            disabled={isGenerating || (user ? false : freeCredits <= 0)}
                        >
                            {isGenerating ? (
                                <>
                                    <Spinner animation="border" size="sm" />  Generating
                                </>
                            ) : (
                                <>
                                    Generate <ArrowRight size={16} color="white" />
                                </>
                            )}
                        </button>
                    </div>
                    {isGenerating && (
                        <ProgressBar
                            now={generationProgress}
                            label={`${generationProgress}%`}
                            variant="warning"
                            animated
                            className="mt-3"
                        />
                    )}
                    <div className="model-and-credits">
                        <div className="model-selection" ref={dropdownRef}>
                            <div className="dropdown-toggle" onClick={toggleDropdown}>
                                {selectedModel === "basic" ? "Basic Model" : "Advanced Model"}
                            </div>
                            {dropdownOpen && (
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={() => handleModelSelect("basic")}>
                                        Basic Model
                                        <span className="model-caption">Fast Output</span>
                                    </div>
                                    <div className="dropdown-item" onClick={() => handleModelSelect("advanced")}>
                                        Advanced Model
                                        <span className="model-caption">High Quality</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="free-credits">
                            {user
                                ? `${selectedModel === "basic" ? "Basic" : "Advanced"} Credits: ${selectedModel === "basic" ? credits.basic : credits.advanced}`
                                : `Free Credits: ${freeCredits}/${totalCredits}`
                            }
                        </div>
                    </div>
                    {showCurvedCarousel && generatedImages.length > 0 && (
                        <div className="curved-carousel-container">
                            <div className="curved-carousel-main">
                                <div className="curved-carousel-outer">
                                    {generatedImages.map((image, index) => (
                                        <div
                                            key={image.id}
                                            className={`curved-carousel-item ${index === activeCurvedCarouselIndex ? 'active' : index === (activeCurvedCarouselIndex + 1) % generatedImages.length ? 'next' : 'prev'}`}
                                            onClick={() => rotateCurvedCarousel(index - activeCurvedCarouselIndex)}
                                        >
                                            <img src={image.image} alt={`Generated thumbnail ${index + 1}`} />
                                            {index === activeCurvedCarouselIndex && (
                                                <Download
                                                    className={`curved-carousel-download-icon ${!user || downloadedImages.length >= (user.planType === 'standard' ? 2 : 1) ? 'disabled' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDownload(image);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
            {!isGeneratePage && (
                <Col xs={12} md={6} className="right-half">
                    <div
                        className="carousel-container book-page"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    >
                        {carouselImages.map((image, index) => (
                            <div
                                key={index}
                                className={`carousel-item ${index === activeCarouselIndex ? 'active' : ''}`}
                            >
                                <img src={image.src} alt={image.alt} />
                            </div>
                        ))}
                        {isHovering && (
                            <>
                                <button className="carousel-button prev" onClick={prevSlide}><ChevronLeft /></button>
                                <button className="carousel-button next" onClick={nextSlide}><ChevronRight /></button>
                            </>
                        )}
                    </div>
                    <p className="carousel-item-text">"{carouselImages[activeCarouselIndex].text}"</p>
                    <div className="carousel-dots">
                        {carouselImages.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === activeCarouselIndex ? 'active' : ''}`}
                                onClick={() => setActiveCarouselIndex(index)}
                            ></span>
                        ))}
                    </div>
                </Col>
            )}
        </Row>
    );

    return (
        <>
            <NavBar activeSection={activeSection} pricingSectionRef={pricingSectionRef} />
            <section id="home" className="home-section" ref={(el) => (sectionRefs.current['home'] = el)}>
                <Container fluid>{renderHomeContent()}</Container>
            </section>
            {!isGeneratePage && (
                <>
                    <section id="features" className="features-section " ref={(el) => (sectionRefs.current['features'] = el)}>
                        <Features />
                    </section>
                    <Element name="pricing">
                        <section id="pricing" className="pricing-section" ref={(el) => (sectionRefs.current['pricing'] = el)}>
                            <Pricing />
                        </section>
                    </Element>
                    <section id="constraints" className="constraints-section" ref={(el) => (sectionRefs.current['constraints'] = el)}>
                        <Constraints />
                    </section>
                    <Banner />
                </>
            )}
        </>
    );
};

export default Home;