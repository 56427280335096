import React from 'react';
import './Resources.css';

const RefundPolicy = ({ renderNavBar }) => {
  return (
    <div className="resource-page">
      {renderNavBar && renderNavBar()}
      <div className="resource-content">
        <h1>Refund and Cancellation Policy</h1>
        <p>Last updated: September 28, 2024</p>

        <p>Thank you for choosing AI Thumbnail. We value your satisfaction and have created this Refund and Cancellation Policy to ensure clarity regarding our services.</p>

        <section>
          <h2>1. Definitions</h2>
          <ul>
            <li><strong>Company</strong> (referred to as "AI Thumbnail", "We", "Us", or "Our"): AI Thumbnail, accessible at <a href='https://www.aithumbnail.in/'>https://aithumbnail.in</a></li>
            <li><strong>Service</strong>: The AI-powered thumbnail generation software provided by AI Thumbnail</li>
            <li><strong>Subscription</strong>: Your paid access to our Service</li>
            <li><strong>You</strong>: The individual or entity using our Service</li>
          </ul>
        </section>

        <section>
          <h2>2. Cancellation Rights</h2>
          <p>You have the right to cancel your Subscription within 14 days of purchase without giving any reason. To exercise this right, you must inform us of your decision through a clear statement via:</p>
          <ul>
            <li>Email: support@hacktogether.dev</li>
            <li>Website: <a href='https://www.aithumbnail.in/'>https://www.aithumbnail.in</a></li>
          </ul>
        </section>

        <section>
          <h2>3. Refund Policy</h2>
          <h3>3.1 Eligibility for Refunds</h3>
          <ul>
            <li>Refunds are available for Subscriptions cancelled within 14 days of purchase.</li>
            <li>Only regular-priced Subscriptions may be refunded. Subscriptions purchased at a discount or during promotional periods are not eligible for refunds.</li>
          </ul>

          <h3>3.2 Refund Process</h3>
          <ul>
            <li>Upon receiving your cancellation request, we will process your refund within 5-7 working days.</li>
            <li>The refund will be credited to the original payment method used for the purchase.</li>
            <li>The refunded amount will be in Indian Rupees (INR).</li>
          </ul>

          <h3>3.3 Non-Refundable Items</h3>
          <p>The following are not eligible for refunds:</p>
          <ul>
            <li>Customized services or features developed specifically for you</li>
            <li>Any portion of the Subscription that has already been used</li>
            <li>Add-ons or extra services purchased separately from the main Subscription</li>
          </ul>
        </section>

        <section>
            <h2>4. Subscription Tiers and Pricing</h2>
            <p>All our subscription tiers and their respective prices are listed in Indian Rupees (INR) on our website. Please refer to <a href='https://www.aithumbnail.in/pricing'>https://www.aithumbnail.in/pricing</a> for the most up-to-date information.</p>
        </section>

        <section>
            <h2>5. Service Delivery</h2>
            <p>As a SaaS product, AI Thumbnail is delivered instantly upon successful subscription. There is no physical shipping involved.</p>
        </section>

        <section><h2>6. Changes to This Policy</h2>
        <p>We reserve the right to modify this policy at any time. Changes will be effective immediately upon posting to our website. Your continued use of our Service after changes are posted constitutes your acceptance of the updated policy.</p>
        </section>



        <section>
          <h2>7. Contact Us</h2>
          <p>If you have any questions about our Refund and Cancellation Policy, please contact us:</p>
          <ul>
            <li>Email:  <a href="mailto:support@hacktogether.dev">support@hacktogether.dev</a></li>
            <li>Website: <a href='https://www.aithumbnail.in/'>https://www.aithumbnail.in</a></li>
            <li>Address: F-18, Bharat enclave, bjb nagar, Bhubaneswar, Odisha, 751014</li>
          </ul>
        </section>

        <p>By using AI Thumbnail, you acknowledge that you have read and agree to this Refund and Cancellation Policy.</p>
      </div>
    </div>
  );
};

export default RefundPolicy;