import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Camera, Edit, Image, Share } from 'react-feather';
import './Features.css';
import hypeVideo from 'src/assets/hype_video_final.webm';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="feature-card">
    <div className="feature-header">
      <div className="icon-wrapper">
        <Icon size={24} />
      </div>
      <h3 className="feature-title">{title}</h3>
    </div>
    <p className="feature-description">{description}</p>
  </div>
);

const Features = () => {
  const features = [
    { 
      icon: Camera, 
      title: "AI-Powered Generation", 
      description: "Harness the power of cutting-edge AI models to create stunning thumbnails tailored to your unique ideas and content."
    },
    { 
      icon: Edit, 
      title: "Intuitive Customization", 
      description: "Easily refine your thumbnails with our user-friendly interface. Describe your vision, and watch as AI brings it to life in real-time."
    },
    { 
      icon: Image, 
      title: "Professional-Grade Quality", 
      description: "Elevate your content with high-resolution, visually striking thumbnails that rival those created by professional designers."
    },
    { 
      icon: Share, 
      title: "Seamless Integration", 
      description: "Effortlessly share your captivating thumbnails across various platforms with just a few clicks, streamlining your content workflow."
    }
  ];

  return (
    <section className="features-section">
      <Container fluid>
        <h2 className="section-title">What We Offer</h2>
        <Row className="features-container">
          <Col lg={7} md={12} className="features-grid-container">
            <div className="features-grid">
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </div>
          </Col>
          <Col lg={5} md={12} className="video-testimonial-container">
            <div className="video-wrapper">
              <video autoPlay loop muted playsInline>
                <source src={hypeVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="testimonial">
              <p className="testimonial-text">
                <a href="https://twitter.com/kirat_tw" target="_blank" rel="noopener noreferrer">@kirat_tw</a>
                and
                <a href="https://twitter.com/mannupaaji" target="_blank" rel="noopener noreferrer">@mannupaaji</a>
              </p>
              <p className="testimonial-subtext">loved our custom model!</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
