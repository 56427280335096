import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from './UserContext';
import DefaultLayout from './components/layout/DefaultLayout';
import Home from './components/Hero/Home';
import PreLaunch from './components/PreLaunch/PreLaunch';
import CheckoutPage from './pages/checkoutPage/CheckoutPage';
import TermsAndConditions from './components/Resources/TermsAndConditions';
import PrivacyPolicy from './components/Resources/PrivacyPolicy';
import RefundPolicy from './components/Resources/RefundPolicy';

const ProtectedRoute = React.memo(({ children }) => {
  const { user, loading } = useUser();
  
  if (loading) return <div>Loading...</div>;
  
  return user ? children : <Navigate to="/" replace />;
});

const AppContent = React.memo(() => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout><Home /></DefaultLayout>} />
      <Route path="/generate" element={<ProtectedRoute><DefaultLayout><Home /></DefaultLayout></ProtectedRoute>} />
      <Route path="/:section" element={<DefaultLayout><Home /></DefaultLayout>} />
      <Route path="/subscribe" element={<PreLaunch />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/terms" element={<DefaultLayout><TermsAndConditions /></DefaultLayout>} />
      <Route path="/privacy" element={<DefaultLayout><PrivacyPolicy /></DefaultLayout>} />
      <Route path="/refund" element={<DefaultLayout><RefundPolicy /></DefaultLayout>} />
    </Routes>
  );
});

export default AppContent;